<template>
    <v-card flat>
      <v-toolbar flat color="info">
            <v-toolbar-title>
                Issues
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-tabs fixed-tabs icons-and-text>
                <v-tab>
                    Open Issues
                    <v-icon left>fas fa-door-open</v-icon>
                </v-tab>
                <v-tab>
                    Closed Issues
                    <v-icon>fas fa-door-closed</v-icon>
                </v-tab>
                <v-tab-item>
                    <v-row>
                        <v-col cols="12" class="text-center subtitle-1">
                            Dotatheme.com now supports clicking on the reported issue to go directly to edit theme in question!
                        </v-col>
                    </v-row>
                    <hr>
                    <v-row v-for="issue in getOpenIssues" :key="issue.id" no-gutters class="entries" @click="goToTheme(issue.themeId)">
                        <v-col cols="12" class="subtitle-1">{{issue.theme}}</v-col>
                        <v-col cols="12"><b>Description:</b> {{issue.request}}</v-col>
                        <v-col cols="12"><b>Submitted:</b> {{issue.created}}</v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item>
                    <v-row class="pl-1" no-gutters>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="issueSearch" color="secondary" label="Search By Theme Name:"></v-text-field>
                        </v-col>
                    </v-row>
                    <hr>
                    <v-row v-for="issue in filterBySearch" :key="issue.id" class="entries" no-gutters>
                        <v-col cols="12" class="subtitle-1">{{issue.theme}}</v-col>
                        <v-col cols="12"><b>Requested Change:</b> {{issue.request}}</v-col>
                        <v-col cols="12" md="6"><b>Opened:</b> {{issue.created}}</v-col>
                        <v-col cols="12" md="6"><b>Closed:</b> {{issue.resolved}}</v-col>
                        <v-col cols="12" md="6"><b>Resolved By:</b> {{issue.resolvedBy}}</v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data(){
        return {
            issueSearch: ""
        }
    },
    methods: {
        goToTheme(id){
            let themeUrl
            this.$router.push(`/edit/${id}`)
        }
    },
    computed: {
        filterBySearch(){
            if (this.issueSearch == "") {
                return this.getClosedIssues
            }
            else {
                return this.getClosedIssues.filter(issue => issue.theme.toLowerCase().includes(this.issueSearch.toLowerCase()))
            }
        },
        getClosedIssues(){
            return this.$store.getters.getIssues.filter(issue => issue.active == false).sort((a, b) => {
                if (a.date > b.date){
                    return 1
                }
                else {
                    return -1
                }
            })
        },
        getOpenIssues(){
            return this.$store.getters.getIssues.filter(issue => issue.active == true).sort((a, b) => {
                if (a.date > b.date){
                    return 1
                }
                else {
                    return -1
                }
            })
        }
    }
}
</script>

<style scoped>
.entries:nth-child(odd){
    background: #d7dae5;
}
</style>